import { Controller } from '@hotwired/stimulus'
import { DispatchEvent, HTMLElementEvent } from '../types'
import { dispatcher } from './pub_controller'

export class LocationFormController extends Controller {
  dispatchNameChangeEvent({
    target: { value },
  }: HTMLElementEvent<HTMLInputElement>) {
    dispatcher(this, 'name', 'address', value)
  }

  dispatchStateProvinceChangeEvent(event: DispatchEvent) {
    const stateOrProvince = event.detail.value.label
    dispatcher(this, 'state_or_province', 'address', stateOrProvince)
  }

  dispatchCountryCodeChangeEvent(event: DispatchEvent) {
    const countryCode = event.detail.value.label
    dispatcher(this, 'country_code', 'address', countryCode)
  }

  dispatchPrimaryPointOfContactDetailChangeEvent(event: DispatchEvent) {
    const contactDetail = event.detail.value.label

    dispatcher(this, 'contact_name', 'address', contactDetail)
  }
}
