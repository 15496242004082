import { Menu } from 'antd'
import { ItemType } from 'antd/lib/menu/interface'
import {
  AppstoreOutlined,
  BarsOutlined,
  EnvironmentOutlined,
  RocketOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { createElement } from 'react'

import { ComponentController } from './component_controller'

type IconType =
  | 'appstore'
  | 'bars'
  | 'environment'
  | 'rocket'
  | 'shop'
  | 'team'
  | 'user'

const iconMap = {
  appstore: createElement(AppstoreOutlined),
  bars: createElement(BarsOutlined),
  environment: createElement(EnvironmentOutlined),
  rocket: createElement(RocketOutlined),
  shop: createElement(ShopOutlined),
  team: createElement(TeamOutlined),
  user: createElement(UserOutlined),
}

type MenuItem = {
  children?: MenuItem[]
  icon?: IconType
  label: string
  url?: string
  key?: string
}

type LeftNavProps = {
  defaultOpenKeys: string[]
  items: MenuItem[]
}

const menuItem = ({ children, icon, label, url, key }: MenuItem): ItemType => {
  const item: Partial<ItemType> = {
    key: key || label,
    label: '',
  }

  if (url) {
    item['label'] = createElement('a', { href: url }, label)
    if (url === window.location.pathname) {
      item['className'] = 'left-nav__item--active'
    }
  } else {
    item['label'] = label
  }

  if (icon) {
    item['icon'] = iconMap[icon]
  }

  if (children) {
    item['children'] = children.map(menuItem)
  }

  return item as ItemType
}

const LeftNav = ({ defaultOpenKeys, items }: LeftNavProps) => {
  return createElement(Menu, {
    defaultOpenKeys,
    mode: 'inline',
    items: items.map(menuItem),
  })
}

export class LeftNavController extends ComponentController {
  get component() {
    return LeftNav
  }

  get initialProps() {
    const props = super.initialProps
    const defaultOpenKeys = props.items.reduce((acc, item) => {
      if (!item.children) {
        return acc
      }
      const isActive = ({ url }: { url?: string }) =>
        url && url === window.location.pathname
      if (item.children.some(isActive)) {
        acc.push(item.key || item.label)
      }
      return acc
    }, [] as string[])
    return { ...props, defaultOpenKeys }
  }
}
