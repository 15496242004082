import { Controller } from '@hotwired/stimulus'
import { DispatchEvent, Location, PrimaryPointOfContact } from '../types'
import { LocalClient } from '../clients'
import { dispatcher } from './pub_controller'

export class SiteForm extends Controller {
  static targets = ['latlongInput', 'primaryContactInput']

  declare readonly latlongInputTarget: HTMLInputElement
  declare readonly hasLatlongInputTarget: boolean
  declare readonly primaryContactInputTarget: HTMLInputElement
  declare readonly hasPrimaryContactInputTarget: boolean
  declare client: LocalClient

  connect() {
    this.initializeClient()
  }

  async handleLocationSelect(event: DispatchEvent) {
    const location_id = event.detail.value.value
    let location: Location

    if (location_id) {
      location = await this.client.fetchLocation(location_id)
    }

    this.setLatLong(location.latlong)
    this.setPrimaryContact(location.primary_point_of_contact)

    dispatcher(
      this,
      'contact_name',
      'site',
      location.primary_point_of_contact.name,
    )
    dispatcher(this, 'latlong', 'site', location.latlong)
    dispatcher(
      this,
      'address_line_one',
      'site',
      location.physical_address.line_one,
    )
    dispatcher(
      this,
      'address_line_two',
      'site',
      location.physical_address.line_two,
    )
    dispatcher(this, 'city', 'site', location.physical_address.city)
    dispatcher(
      this,
      'state_or_province',
      'site',
      location.physical_address.state_or_province,
    )
    dispatcher(
      this,
      'postal_code',
      'site',
      location.physical_address.postal_code,
    )
    dispatcher(
      this,
      'country_code',
      'site',
      location.physical_address.country_code,
    )
  }

  setPrimaryContactData(event: DispatchEvent) {
    const primary_point_of_contact = event.detail.value

    dispatcher(this, 'contact_name', 'site', primary_point_of_contact.label)
  }

  private setLatLong(latlong: string) {
    this.latlongInputTarget.value = latlong || ''
  }

  private setPrimaryContact(primary_point_of_contact: PrimaryPointOfContact) {
    this.dispatch('set-primary-contact', {
      detail: {
        content: {
          label: primary_point_of_contact.name,
          value: primary_point_of_contact.uuid,
        },
      },
    })
  }

  private initializeClient() {
    this.client = new LocalClient()
  }
}
