import { Select } from 'antd'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'

import { ComponentController } from './component_controller'
import { pushBinding } from '../binding_controller'
import { DispatchEvent, SelectOptionSingle } from '../../types'

export class MultiSelectController extends ComponentController<SelectProps> {
  static targets = ['component', 'count', 'input']

  declare componentTarget: HTMLElement
  declare countTarget: HTMLElement
  declare hasCountTarget: boolean
  declare inputTarget: HTMLInputElement

  connect() {
    super.connect()
    this.inputTarget.addEventListener('change', this.onHiddenInputChanged)
  }

  disconnect(): void {
    super.disconnect()
    this.inputTarget.removeEventListener('change', this.onHiddenInputChanged)
  }

  update(event: DispatchEvent<{ content: SelectOptionSingle[] }>) {
    super.update(event)

    this.inputTarget.value = event.detail.content.value?.join(',') || ''
  }

  get component() {
    return Select
  }

  get initialProps() {
    const props = this.propsFromData
    const initialValues = this.inputTarget.value
      ? this.getSelectedOptions(
          props.options,
          this.inputTarget.value.split(','),
        )
      : undefined
    return {
      ...props,
      defaultValue: initialValues,
      maxTagCount: 'responsive',
      maxTagPlaceholder: (ommittedValues: string[]) =>
        `+${ommittedValues.length}${props.size === 'small' ? '' : ' items'}`, // TODO: i18n
      onChange: this.onChange.bind(this),
      value: initialValues,
      virtual: false,
    }
  }

  onChange(value: any) {
    const selectedOptions = this.getSelectedOptions(this.props.options, value)

    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new Event('change'))
    this.updateProps({ value })
    pushBinding({
      controller: this,
      attribute: 'change',
      value: selectedOptions,
      prefix: 'select',
    })

    this.updateCountTarget(selectedOptions.length)
  }

  onHiddenInputChanged = (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    const option = this.props.options.find(
      (option: any) => option.value == value,
    )
    this.updateProps({ value: option })
  }

  get rootElement() {
    return this.componentTarget
  }

  reset() {
    console.log('reset')
    this.updateProps({ options: [], value: [] })
  }

  private getSelectedOptions(
    options: DefaultOptionType[],
    value: any[],
  ): DefaultOptionType[] {
    return options
      .map((opt) => ('options' in opt ? opt.options : opt))
      .flat()
      .filter((opt) => 'value' in opt && value.includes(opt.value))
  }

  private updateCountTarget(count: number) {
    if (this.hasCountTarget) {
      this.countTarget.innerText = count != 0 ? `(${count})` : ''
    }
  }
}
