import { Select } from 'antd'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'

import {
  DispatchEvent,
  HTMLElementEvent,
  SelectOptionSingle,
} from '../../types'
import { pushBinding } from '../binding_controller'
import { ComponentController } from './component_controller'

export class SelectController extends ComponentController<SelectProps> {
  static targets = ['component', 'input']

  declare componentTarget: HTMLElement
  declare inputTarget: HTMLInputElement

  connect() {
    super.connect()
    this.inputTarget.addEventListener(
      'change',
      this.onHiddenInputChanged.bind(this),
    )
  }

  disconnect(): void {
    super.disconnect()
    this.inputTarget.removeEventListener(
      'change',
      this.onHiddenInputChanged.bind(this),
    )
  }

  update(event: DispatchEvent<{ content: SelectOptionSingle }>) {
    super.update(event)

    this.inputTarget.value = event.detail.content.value || ''
  }

  get component() {
    return Select
  }

  get initialProps() {
    const props = this.propsFromData
    const initialValue = this.inputTarget.value
      ? this.getSelectedOption(props.options, this.inputTarget.value)
      : undefined

    return {
      ...props,
      defaultValue: initialValue,
      onChange: this.onChange.bind(this),
      value: initialValue,
      virtual: false,
    }
  }

  onChange(value: any) {
    const selectedOption = this.getSelectedOption(this.props.options, value)

    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new Event('change'))
    this.updateProps({ value })
    pushBinding({
      controller: this,
      attribute: 'change',
      value: selectedOption,
      prefix: 'select',
    })
  }

  onHiddenInputChanged({
    target: { value },
  }: HTMLElementEvent<HTMLInputElement>) {
    const option = this.getSelectedOption(this.props.options, value)
    this.updateProps({ value: option })
  }

  get rootElement() {
    return this.componentTarget
  }

  private getSelectedOption(
    options: DefaultOptionType[],
    value: any,
  ): DefaultOptionType {
    return options.find((opt: DefaultOptionType) => opt.value == value)
  }
}
