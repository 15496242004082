import { Marker, Map } from 'mapbox-gl'

import { Geocodeable } from '../types'
import { LocalClient } from '../clients'
import { MapboxController } from './mapbox_controller'

export class SearchableMap extends MapboxController {
  static targets = ['searchableMap']
  declare readonly searchableMapTarget: HTMLElement

  connect() {
    this.setTokens()
    this.initializeMap(this.searchableMapTarget)
    this.initializeMarkers()
  }

  private async initializeMarkers() {
    const geocodeables = await LocalClient.queryCurrentPath<Geocodeable[]>({
      preserveExistingParams: true,
    })

    this.plotGeocodables(geocodeables)

    this.flyToMarkers()
  }
}
