import mapboxgl from 'mapbox-gl'

import { DispatchEvent } from '../types'
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  OVERRIDE_MARKER_COLOR,
  DEFAULT_FLY_TO_ZOOM,
} from '../constants'
import { MapboxAddress } from '../models'
import { GeocodeableAddressableMapController } from './geocodeable_addressable_map_controller'

export class OverrideableGeocodeableAddressableMapController extends GeocodeableAddressableMapController {
  overrideMarker?: mapboxgl.Marker = undefined
  verifiedMarker?: mapboxgl.Marker = undefined
  static targets = ['map', 'addMapMarkerButton']
  declare readonly addMapMarkerButtonTarget: HTMLButtonElement
  declare readonly hasAddMapMarkerButtonTarget: boolean
  declare readonly mapTarget: HTMLInputElement
  declare readonly hasMapTarget: boolean

  connect() {
    this.setTokens()
    this.initializeMap(this.mapTarget)
    this.displayAddMapMarkerButton()
  }

  handleAddressLatlongChangeEvent({ detail: latlong }: DispatchEvent<string>) {
    const [latitude, longitude] = latlong.split(',')
    this.setVerifiedMarker(latitude, longitude)

    this.clearOverrideMarker()
  }

  handleLatlongChangeEvent({ detail: latlong }: DispatchEvent<MapboxAddress>) {
    if (latlong) {
      const [latitude, longitude] = latlong.split(',')
      this.setOverrideMarker({
        latitude,
        longitude,
      })
    } else {
      this.clearOverrideMarker()
    }
  }

  setOverrideMarker({
    latitude = DEFAULT_LATITUDE,
    longitude = DEFAULT_LONGITUDE,
    shouldFlyToMarkers = true,
  }) {
    if (this.addMapMarkerButtonTarget) {
      this.clearMarkers([this.overrideMarker])

      const marker = this.addMarker(latitude, longitude, {
        draggable: true,
        color: OVERRIDE_MARKER_COLOR,
      })

      marker?.on('dragend', () => {
        const { lat, lng } = marker.getLngLat()

        this.updateLatlong(`${lat},${lng}`)
      })

      this.overrideMarker = marker

      shouldFlyToMarkers &&
        this.flyToMarkers({ opts: { maxZoom: DEFAULT_FLY_TO_ZOOM } })
    }
  }
  private updateLatlong(latlong: string) {
    this.dispatch('latlong-change', { detail: latlong })
  }

  private setOverrideLocation() {
    const mapCenter = this.map.getCenter()
    const latitude = mapCenter.lat.toString() || DEFAULT_LATITUDE
    const longitude = mapCenter.lng.toString() || DEFAULT_LONGITUDE

    this.setOverrideMarker({ latitude, longitude, shouldFlyToMarkers: false })
    this.updateLatlong(`${latitude},${longitude}`)
  }

  protected resetMarkers(latitude: string, longitude: string) {
    this.clearMarkers()
    this.setVerifiedMarker(latitude, longitude)
    this.setOverrideMarker({ latitude, longitude })
  }

  private clearOverrideMarker() {
    this.clearMarkers([this.overrideMarker])

    this.flyToMarkers()
  }

  private displayAddMapMarkerButton() {
    this.map.on('style.load', () => {
      const button = this.addMapMarkerButtonTarget
      button.classList.toggle('visible')
      button.ariaHidden = 'false'
      button.ariaLabel = 'Click to add a marker to the map'
      button.disabled = false
      button.onclick = this.setOverrideLocation.bind(this)
    })
  }
}
