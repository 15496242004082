import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace'

import {
  DispatchEvent,
  HTMLElementEvent,
  MapboxAddressFeatureCoordinates,
} from '../types'
import { MapboxAddress } from '../models'
import { LocalClient } from '../clients'
import { MapboxController } from './mapbox_controller'
import { dispatcher } from './pub_controller'

export class VerifiedAddressController extends MapboxController {
  static targets = [
    'city',
    'county',
    'country',
    'destroyFlag',
    'lineOne',
    'lineTwo',
    'postalCode',
    'stateOrProvince',
    'timeZone',
  ]

  static values = {
    pubChannel: {
      type: String,
      default: 'address',
    },
  }

  declare readonly cityTarget: HTMLInputElement
  declare readonly hasCityTarget: boolean
  declare readonly countyTarget: HTMLInputElement
  declare readonly hasCountyTarget: boolean
  declare readonly countryTarget: HTMLSelectElement
  declare readonly hasCountryTarget: boolean
  declare readonly destroyFlagTarget: HTMLInputElement
  declare readonly lineOneTarget: HTMLInputElement
  declare readonly hasLineOneTarget: boolean
  declare readonly lineTwoTarget: HTMLInputElement
  declare readonly hasLineTwoTarget: boolean
  declare readonly postalCodeTarget: HTMLInputElement
  declare readonly hasPostalCodeTarget: boolean
  declare readonly stateOrProvinceTarget: HTMLSelectElement
  declare readonly hasStateOrProvinceTarget: boolean
  declare readonly timeZoneTarget: HTMLSelectElement
  declare readonly hasTimeZoneTarget: boolean

  declare readonly pubChannelValue: string

  connect() {
    this.setTokens()
    this.element.addEventListener('change', this.clearDestroyFlag)
  }

  disconnect() {
    this.element.removeEventListener('change', this.clearDestroyFlag)
  }

  handleSuggestedAddressSelectionEvent({
    detail: address,
  }: DispatchEvent<MapboxAddress>) {
    this.setAddressFields(address)

    dispatcher(this, 'address_line_one', this.pubChannelValue, address.lineOne)
    dispatcher(this, 'address_line_two', this.pubChannelValue, address.lineTwo)
    dispatcher(this, 'city', this.pubChannelValue, address.city)
    dispatcher(this, 'county', this.pubChannelValue, address.county)
    dispatcher(
      this,
      'state_or_province',
      this.pubChannelValue,
      address.stateOrProvince,
    )
    dispatcher(this, 'postal_code', this.pubChannelValue, address.postalCode)
    dispatcher(this, 'country_code', this.pubChannelValue, address.country)
    dispatcher(this, 'latlong', this.pubChannelValue, address.latlong)
  }

  clear() {
    this.clearAddressFields()
  }

  async fetchSuggestedAddressDetails(event: HTMLElementEvent<HTMLElement>) {
    const { id, json } = event.target.dataset
    const { context, full_address } = JSON.parse(json)

    const forwardGeocodeAddressPayload =
      await LocalClient.fetchSuggestedAddressDetails(full_address)

    const addressProperties =
      forwardGeocodeAddressPayload.features[0]?.properties

    const address = new MapboxAddress(
      addressProperties.mapbox_id || id,
      addressProperties.context || context,
      addressProperties.coordinates,
    )

    this.dispatch('address-verified', { detail: address })
  }

  private clearAddressFields() {
    this.setAddressLineOneValue('')
    this.setAddressLineTwoValue('')
    this.setAddressCityValue('')
    if (this.hasCountyTarget) {
      this.setAddressCountyValue('')
    }
    this.setAddressStateOrProvinceValue('')
    this.setAddressPostalCodeValue('')
    this.setAddressCountryValue('')
    this.setTimeZoneValue(null)
    this.setDestroyFlag()

    this.dispatch('address-cleared')
  }

  private setAddressFields(address: MapboxAddress) {
    this.setAddressLineOneValue(address.lineOne)
    this.setAddressCityValue(address.city)
    this.setAddressCountyValue(address.county)
    this.setAddressStateOrProvinceValue(address.stateOrProvince)
    this.setAddressPostalCodeValue(address.postalCode)
    this.setAddressCountryValue(address.country)
    this.setTimeZoneValue(address.coordinates)
  }

  private setAddressLineOneValue(value: string) {
    this.lineOneTarget.value = value
  }

  private setAddressLineTwoValue(value: string) {
    this.lineTwoTarget.value = value
  }

  private setAddressCityValue(value: string) {
    this.cityTarget.value = value
  }

  private setAddressCountyValue(value: string) {
    if (this.hasCountyTarget) {
      this.countyTarget.value = value
    }
  }

  private setAddressStateOrProvinceValue(value: string) {
    this.stateOrProvinceTarget.value = value
    this.stateOrProvinceTarget.dispatchEvent(new Event('change'))
  }

  private setAddressPostalCodeValue(value: string) {
    this.postalCodeTarget.value = value
  }

  private setAddressCountryValue(value: string) {
    this.countryTarget.value = value
    this.countryTarget.dispatchEvent(new Event('change'))
  }

  private setTimeZoneValue(coordinates: MapboxAddressFeatureCoordinates) {
    if (this.hasTimeZoneTarget) {
      if (coordinates) {
        const timeZone = getFuzzyLocalTimeFromPoint(Date.now(), [
          coordinates.longitude,
          coordinates.latitude,
        ])

        this.timeZoneTarget.value = timeZone._z.name
      } else {
        this.timeZoneTarget.value = ''
      }

      this.timeZoneTarget.dispatchEvent(new Event('change'))
    }
  }

  private setDestroyFlag() {
    this.destroyFlagTarget.value = 'true'
  }

  private clearDestroyFlag = () => {
    this.destroyFlagTarget.value = 'false'
  }
}
